.card {
  -webkit-text-size-adjust: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 13px;
  box-shadow: 12px 12px 30px rgba(0, 0, 0, 0.15);
  width: auto;
  padding: 5% 5% 7% 9%;
}

.card :hover {
  cursor: pointer;
}

.link {
  text-decoration: none;
}

.logo_container {
  min-height: 20%;
  margin-bottom: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}

.logo {
  max-width: 85%;
  max-height: 85%;
  object-fit: contain;
}

.name {
  min-height: 1.2em;
  font-size: 24px;
  font-weight: 600;
  margin: 5px 0 0 0;
}

.benefits {
  min-height: 1.2em;
  margin: 3px 0 0 0;
  font-weight: 500;
  color: #979797;
}

.min {
  min-height: 1.2em;
  margin: 10px 0 0 0;
  font-weight: 500;
  color: #979797;
}

.max {
  min-height: 1.2em;
  margin: 10px 0 0 0;
  font-weight: 500;
  color: #979797;
}

.data {
  min-height: 1.2em;
  margin: 5px 0 0 0;
  color: black;
}
