@charset "UTF-8";

@font-face {
  font-family: Gilroy;
  font-display: swap;
  src: url("./Gilroy-Regular.woff2") format("woff2"), url("./Gilroy-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  font-display: swap;
  src: url("./Gilroy-Medium.woff2") format("woff2"), url("./Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: icons;
  font-display: swap;
  src: url("./icons.woff2") format("woff2"), url("./icons.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
