.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 13px;
  box-shadow: 12px 12px 30px rgba(0, 0, 0, 0.15);
  height: 100%;
  padding: 1vw 1vw 2vw 2vw;
  min-height: 10em;
  max-width: 100%;
  min-width: 20%;
}

.logo_container {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.logo {
  max-width: 85%;
  max-height: 85%;
  object-fit: contain;
}

.name {
  display: flex;
  font-size: 18px;
  margin: 18px 0 0 0;
  text-overflow: ellipsis;
  text-decoration: none;
  color: black;
  text-decoration-line: none;
  text-decoration: none;
}

.textarea {
  min-height: 3em;
}

.link {
  text-decoration: none;
}
