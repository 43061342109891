.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 0 0;
  margin-bottom: 28px;
  width: 100%;
}

.country_name {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
}

.country_name_container {
  display: flex;
  width: 100%;
  padding: 0;
}

.geoloc_icon {
  margin: 0 10px 0 0;
}

.country_name_text {
  padding: 0;
  font-size: 24px;
  text-decoration-line: underline;
  color: #000000;
}

.country_name_text:hover {
  cursor: default;
  color: blue;
}

.bonus_text {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 130.4%;
  color: #9faebb;
}

@media (min-width: 74.375em) {
  .logo_image {
    max-width: 10rem;
  }
}

@media (min-width: 20em) and (max-width: 74.375em) {
  .logo_image {
    max-width: calc(6.25rem + 3.75 * (100vw - 20rem) / 54.375);
  }
}

@media (max-width: 20em) {
  .logo_image {
    max-width: 6.25rem;
  }
}

@media (min-width: 74.375em) {
  .bonus {
    max-width: 31.25rem;
  }
}

@media (min-width: 20em) and (max-width: 74.375em) {
  .bonus {
    max-width: calc(9.25rem + 22 * (100vw - 20rem) / 54.375);
  }
}

@media (max-width: 20em) {
  .bonus {
    max-width: 9.25rem;
  }
}

@media (min-width: 74.375em) {
  .bonus_text {
    font-size: 1.5rem;
  }
}

@media (min-width: 20em) and (max-width: 74.375em) {
  .bonus_text {
    font-size: calc(0.875rem + 0.625 * (100vw - 20rem) / 54.375);
  }
}

@media (max-width: 20em) {
  .bonus_text {
    font-size: 0.875rem;
  }
}
