.popup {
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 1s linear;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5;
}

.popup_open {
  visibility: visible;
  opacity: 1;
}

.form {
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  width: 560px;
  height: auto;
}

.form_confirm_country {
  background-color: white;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 2%;
  margin: auto;
  min-width: 300px;
  max-width: 450px;
  height: auto;
}

.title_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: auto;
}

.title {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #000;
  margin: 2vh 0;
}

.text {
  margin: 0 auto;
  text-align: center;
  line-height: 120%;
  font-size: 150%;
}

.text_last {
  margin: 2vh 0 0 0;
}

.preloader {
  height: 7vw;
  min-height: 60px;
  /* width: 120px; */
  padding: 0;
  margin: 2vw auto 0;
}

.button_hidden {
  display: none;
}

.element_hidden {
  display: none;
}

.hidden {
  display: none;
}

.buttons_container {
  width: 100%;
  margin: 4vh 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.button {
  /* margin: auto; */
  padding: 15px 30px;
  border-radius: 6px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130.4%;
  color: #ffffff;
  transition: all 0.3s ease-out 0s;
}

.button_green {
  background: #25e472;
}

.button_grey {
  background: #9faebb;
}
