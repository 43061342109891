.form__support {
  margin: auto;
}


.content__name_input {
  width: calc(100% - 30px);
  border: 1px solid #dbe4ef;
  border-radius: 6px;
  margin-bottom: 14px;
  padding: 10px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130.4%;
  color: #323232;
}

.container__info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 130.4%;
  color: #9faebb;
  margin-bottom: 24px;
}

.container__phone {
  width: calc(50% - 15px);
  display: flex;
}

.content__phone_input {
  border: 1px solid #dbe4ef;
  border-radius: 6px;
  padding: 10px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130.4%;
  color: #323232;
}

.container__email {
  display: flex;
  width: calc(50% - 15px);
}

.content__email_input {
  border: 1px solid #dbe4ef;
  border-radius: 6px;
  padding: 10px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130.4%;
  color: #323232;
}

.content__textarea {
  width: calc(100% - 30px);
  border: 1px solid #dbe4ef;
  border-radius: 6px;
  margin-bottom: 14px;
  height: 25vh;
  padding: 10px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130.4%;
  color: #323232;
}

.buttons__body {
  display: flex;
  justify-content: space-between;
}

.buttons__body_btn-continue {
  padding: 15px 30px;
  background: #25e472;
  border-radius: 6px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130.4%;
  /* or 23px */
  color: #ffffff;
  transition: all 0.3s ease-out 0s;
}
.support__button {
  margin-left: auto;
}
@media (any-hover: hover) {
  .buttons__body_btn-continue:hover {
    background-color: #1dc461;
  }
}
