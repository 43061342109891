.footer__main {
  margin-bottom: 80px;
}

.block__logo {
  margin: 3vh 0 2vh 0;
}

.block__logo-image {
  position: relative;
  z-index: 3;
}

.block__text-p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130.4%;
  color: #9faebb;
  opacity: 0.5;
}

.nav__body {
  width: 100%;
}

.nav__list {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  list-style: none;
  padding: 0;
}

.nav__list_items {
  list-style: none;
  padding: 0;
}

.nav__list_item {
  margin-bottom: 16px;
}

.nav__list_link {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 195.9%;
  /* or 35px */
  color: #000000;
  text-decoration: none;
  opacity: 0.7;
  transition: all 0.5s ease 0s;
}

.info {
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130.4%;
  color: #9faebb;
}

.info__tel {
  display: flex;
  max-width: fit-content;
  margin-bottom: 13px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130.4%;
  color: #9FAEBB;
  text-decoration: none;
}

.info__adress {
  margin-bottom: 13px;
}

@media (any-hover: hover) {
  .nav__list_link:hover {
    opacity: 0.9;
  }
}

@media (max-width: 747px) {
  .nav__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 2;
  }
}

@media (max-width: 670px) {
  .nav__list {
    display: grid;
    grid-template-columns: 40% 40% 20%;
    grid-template-rows: 3;
  }
}

@media (max-width: 586px) {
  .nav__list {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 3;
  }
}

@media (max-width: 460px) {
  .nav__list {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 3;
  }
}

@media (max-width: 318px) {
  .nav__list {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 5;
  }
}

@media (min-width: 61.99875em) {
  .block__text {
    max-width: 255px;
  }
}

@media (min-width: 74.375em) {
  .block__logo-image {
    max-width: 10rem;
  }
}

@media (min-width: 20em) and (max-width: 74.375em) {
  .block__logo-image {
    max-width: calc(6.25rem + 3.75 * (100vw - 20rem) / 54.375);
  }
}

@media (max-width: 20em) {
  .block__logo-image {
    max-width: 6.25rem;
  }
}

@media (min-width: 74.375em) {
  .footer {
    padding-top: 3.75rem;
  }
}

@media (min-width: 20em) and (max-width: 74.375em) {
  .footer {
    padding-top: calc(1.5625rem + 2.1875 * (100vw - 20rem) / 54.375);
  }
}

@media (max-width: 20em) {
  .footer {
    padding-top: 1.5625rem;
  }
}

@media (min-width: 74.375em) {
  .footer {
    padding-bottom: 2.0625rem;
  }
}

@media (min-width: 20em) and (max-width: 74.375em) {
  .footer {
    padding-bottom: calc(1.5625rem + 0.5 * (100vw - 20rem) / 54.375);
  }
}

@media (max-width: 20em) {
  .footer {
    padding-bottom: 1.5625rem;
  }
}

@media (min-width: 61.99875em) {
  .footer__main {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
}

@media (min-width: 74.375em) {
  .footer__main {
    margin-bottom: 5rem;
  }
}

@media (min-width: 20em) and (max-width: 74.375em) {
  .footer__main {
    margin-bottom: calc(4.25rem + 0.75 * (100vw - 20rem) / 54.375);
  }
}

@media (max-width: 20em) {
  .footer__main {
    margin-bottom: 4.25rem;
  }
}
