input,
button,
textarea {
  font-family: Gilroy;
  font-size: inherit;
}

button {
  cursor: pointer;
  color: inherit;
  background-color: inherit;
}

.transfer-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: rgba(196, 196, 196, 0.4);
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.5s ease 0s;
}

.transfer-popup__element_hidden {
  display: none;
}

.transfer-popup_open {
  opacity: 1;
  visibility: visible;
}

.transfer-popup_open .transfer-popup__form {
  transform: scale(1);
  opacity: 1;
}

.transfer-popup__area {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.transfer-popup__body {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease 0s;
}

.transfer-popup__form {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 2vw;
  box-shadow: 12px 12px 30px rgba(0, 0, 0, 0.15);
  border-radius: 13px;
  position: relative;
  margin: auto;
  transition: all 0.5s ease 0s;
  opacity: 0;
  width: 40%;
  max-width: 350px;
  min-width: 290px;
  transform: scale(0);
}

.content__howmuch {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.content__howmuch_title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 130.4%;
  /* or 31px */
  color: #9faebb;
  margin-bottom: 5px;
}

.content__label-text {
  font-size: 0.6em;
  line-height: 110%;
}

.content__howmuch_input {
  box-sizing: border-box;
  border: 1px solid #dbe4ef;
  border-radius: 6px;
  margin-bottom: 14px;
  width: 100px;
  padding: 10px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130.4%;
  color: #323232;
}

.content__address_title-transfer {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.content__address_input-transfer {
  box-sizing: border-box;
  border: 1px solid #dbe4ef;
  border-radius: 6px;
  padding: 10px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130.4%;
  /* or 23px */
  color: #323232;
  margin-bottom: 5px;
}

.transfer-popup__content__info {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 130.4%;
  color: #9faebb;
  margin-bottom: 24px;
}

.content__phone {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* width: 50%; */
}

.content__phone_title {
  margin-bottom: 12px;
}

.content__phone_input {
  border-radius: 6px;
  padding: 10px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130.4%;
  /* or 23px */
  color: #323232;
}

.content__address {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content__address_title {
  margin-bottom: 12px;
}

.buttons__btn-body {
  width: 40%;
  margin-top: 5px;
  padding: 15px 0;
  border: none;
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130.4%;
  transition: all 0.3s ease-out 0s;
}

.buttons__btn-body_green {
  background: #25e472;
  color: #ffffff;
}

.buttons__btn-body_grey {
  background: #d3d7e0;
  color: #aaafbb;
}

.content__buttons_transfer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 } 

 .button-transfer__text {
   margin: auto;
 }

 @media screen and (max-width: 570px) {
  .content__buttons_transfer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
   } 
   .buttons__btn-body {
    width: 50%;
    min-width: 90px;
  }
}

@media screen and (max-width: 335px) {
  .content__buttons_transfer {
    display: flex;
    flex-direction: column-reverse;
   } 
   .buttons__btn-body {
    width: 50%;
  }
}
