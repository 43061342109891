.providers {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.title_text {
  margin: 0;
  height: 100%;
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 109.9%;
  color: #000000;
}

.title_button {
  margin-left: 30px;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  padding: auto;
  border: none;
  outline: none;
  background-color: white;
}

.grid {
  margin-top: 6vw;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  gap: 40px 20px;
}

@media screen and (max-width:760px) {
  .grid {
    grid-template-columns: repeat(3, 30%);
  }
}

@media screen and (max-width:560px) {
  .grid {
    grid-template-columns: repeat(2, 40%);
  }
}

@media screen and (max-width:590px) {
  .title_text {
    font-size: 40px;
  }
}

@media screen and (max-width:500px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width:430px) {
  .title_text {
    font-size: 30px;
  }
}

@media screen and (max-width:405px) {
  .grid {
    margin-left: 10px;
    margin-right: 10px;
    grid-template-columns: repeat(2, 50%);
  }
}

@media screen and (max-width:340px) {
  .title_text {
    font-size: 25px;
  }
}
