.main {
  display: flex;
  flex-direction: column;
}

.buyairtime {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.text_element {
  margin-top: 25px;
}

.text_title {
  /* font-family: Gilroy; */
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 109.9%;
  color: #000000;
}

.text_subtitle {
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 130.4%;
  color: #9FAEBB;
  width: 75%;
}

.picture {
  /* width: 30%; */
  height: 100%;
}

@media screen and (max-width:880px) {
  .picture {
    display: none;
  }

  .text {
    width: 60%;
    display: flex;
    flex-direction: column;
  }

  .text_title {
    font-size: 60px;
  }
}

@media screen and (max-width:1110px) {
  .text_title {
    font-size: 55px;
  }
}

@media screen and (max-width:1020px) {
  .text_title {
    font-size: 50px;
  }
}

@media screen and (max-width:775px) {
  .text_title {
    font-size: 45px;
  }
}

@media screen and (max-width:700px) {
  .text_title {
    font-size: 40px;
  }

  .buyaitrime__text_subtitle {
    font-size: 18px;
    width: 90%;
  }
}

@media screen and (max-width:625px) {
  .text_title {
    font-size: 35px;
  }
}

@media screen and (max-width:550px) {
  .text_title {
    font-size: 30px;
  }
}

@media screen and (max-width:460px) {
  .text_title {
    font-size: 25px;
  }

  .buyaitrime__text_subtitle {
    font-size: 13px;
    width: 90%;
  }
}

@media screen and (max-width:380px) {
  .text_title {
    font-size: 20px;
  }
}
