.doc__title-h1 {
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
  font-size: 1.9rem;
  /* or 66px */
  color: #000000;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
@media (min-width: 74.375em) {
  .doc__title-h1 {
    font-size: 1.8rem;
  }
}
@media (min-width: 20em) and (max-width: 74.375em) {
  .doc__title-h1 {
    /* font-size: calc(1.2rem + 2.25 * (100vw - 20rem) / 54.375); */
    font-size: 1.7rem;
  }
}
@media (max-width: 20em) {
  .doc__title-h1 {
    font-size: 1.7rem;
  }
}
.doc__text-p {
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  font-size: 1.4rem;
  color: #000000;
  margin-bottom: 0.8rem;
}
@media (min-width: 74.375em) {
  .doc__text-p {
    font-size: 1.2rem;
  }
}
@media (min-width: 20em) and (max-width: 74.375em) {
  .doc__text-p {
    /* font-size: calc(1.2rem + 2.25 * (100vw - 20rem) / 54.375); */
    font-size: 1.05rem;
  }
}
@media (max-width: 20em) {
  .doc__text-p {
    font-size: 1rem;
  }
} 
.doc__text-p_special {
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  font-size: 1.4rem;
  color: #000000;
  margin-bottom: 0.8rem;
}
