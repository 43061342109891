input,
button,
textarea {
  font-family: Gilroy;
  font-size: inherit;
  line-height: 100%;
}

button {
  cursor: pointer;
  color: inherit;
  background-color: inherit;
}

.layout {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  z-index: 5;
  background: rgba(196, 196, 196, 0.4);
  box-shadow: 12px 12px 30px rgba(0, 0, 0, 0.15);
  border-radius: 13px;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.5s ease 0s;
}

.body {
  background-color: #fff;
  width: 300px;
  height: 270px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em;
  transition: all 0.5s ease 0s;
}

.button_area {
  display: flex;
  width: 100%;
  justify-content: center;
}

.image {
  width: 80px;
  height: 80px;
}

@media (max-width: 570px) {
  .body {
    transform: scale(0.8);
  }
}

@media (max-width: 29.99875em) {
  .body {
    transform: scale(0.5);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  min-height: 100%;
  margin: 0 auto;
  color: #000;
  position: relative;
  transition: all 0.5s ease 0s;
}

.btn_close {
  border: none;
  min-width: 130px;
  padding: 15px 30px;
  background: #d3d7e0;
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130.4%;
  /* or 23px */
  color: #aaafbb;
  transition: all 0.5s ease 0s;
}

@media (any-hover: hover) {
  .btn_close:hover {
    background: #bcc0cc;
    color: #fff;
  }
}
