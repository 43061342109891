.choose {
  display: flex;
  flex-direction: column;
  /* display: none; */
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 1s linear;
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 1000;
}

.form_input {
  width: 70%;
  margin-bottom: 30px;
  height: 30px;
  font-size: 25px;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
}

.choose_open {
  visibility: visible;
  /* display: flex; */
  opacity: 1;
}

.countries {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(40, 1fr);
  gap: 0.5vw 0.5vw;
  grid-auto-flow: column;
  padding: 1vw;
  margin-right: auto;
  margin-left: 10px;
}

.country_link {
  cursor: default;
  text-decoration-line: underline;
  margin-bottom: 6px;
}

.country_link:hover {
  color: blue;
}

@media screen and (max-width: 820px) {
  .countries {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
